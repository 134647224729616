import {current_date} from "../../../components/utilities";
import foreign_keys from "../foreign_keys";

export default {
    type: 'table',
    name: 'store_stocks',
    tab_name: 'Store Stocks',
    table: 'store_stocks',
    title: ['date', 'item', 'quantity'],
    lines: [
        [
            {name: 'Store', id: 'store_id', type: 'select', source: 'stores.description', required: true},
            {name: 'Date', id: 'date', type: 'date', required: true, defaultValue: current_date()},
            {name: 'Item', id: 'item_id', type: 'select', source: 'items.description', required: true, showInTable: true, show_pk_in_list: false},
        ], [
            {name: 'Buying Price', id: 'items.unit_cost', type: 'currency', reference: 'item_id', disabled: false, required: true},
            {name: 'Quantity', id: 'quantity', type: 'number', required: true},
            {name: 'Total', id: 'total', type: 'currency', disabled: true},
        ], [
            {name: 'Investor', id: 'investor_id', type:'select', source:'investors.name', span:1, show_pk_in_list: false, required: true},
            {name: 'Notes', id: 'notes', type: 'textarea', span:1},
            {name: 'Cleared', id: 'cleared', ...foreign_keys.yes_no_boolean, defaultValue: false}
        ]
    ]
}