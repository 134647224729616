import React from 'react'
import foreign_keys from "../foreign_keys";
import order_items from "./order_items";
import {add_years, current_date} from "../../../components/utilities";
import {OSMMap, mapStateToProps, mapDispatchToProps} from "./maps/MapsOSM";
import {connect} from "react-redux";

// let options = [
//     {name: 'Ordering', value: 'ordering'},
//     {name: 'Confirming', value: 'confirming'},
//     {name: 'Preparing / Packaging', value: 'preparing'},
//     {name: 'Ready', value: 'ready'},
//     {name: 'Awaiting Pick Up', value: 'pick_up'},
//     {name: 'Dispatched / Drop Off', value: 'drop_off'},
//     {name: 'Sold', value: 'fulfilled'},
//     {name: 'Cancelled', value: 'cancelled'},
// ]

// let Status = function (props) {
//     let
//         record = props.record || props.form_values || {},
//         word = null,
//         getName = value => (options.find(item => item.value === value) || {}).name
//     if (record.order_stage === 'ordering' || record.order_stage === null) {
//         word = (<div className='col-md-12'><span className="badge bg-blue">{getName(record.order_stage)}</span></div>)
//     } else if (record.order_stage === 'confirming') {
//         word = (<div className='col-md-12'><span className="badge bg-fuchsia">{getName(record.order_stage)}</span></div>)
//     } else if (record.order_stage === 'preparing') {
//         word = (<div className='col-md-12'><span className="badge bg-yellow">{getName(record.order_stage)}</span></div>)
//     } else if (record.order_stage === 'ready') {
//         word = (<div className='col-md-12'><span className="badge bg-info">{getName(record.order_stage)}</span></div>)
//     } else if (record.order_stage === 'pick_up') {
//         word = (<div className='col-md-12 blink'><span className="badge bg-aqua">{getName(record.order_stage)}</span></div>)
//     } else if (record.order_stage === 'drop_off') {
//         word = (<div className='col-md-12 blink'><span className="badge bg-green-gradient">{getName(record.order_stage)}</span></div>)
//     } else if (record.order_stage === 'fulfilled') {
//         word = (<div className='col-md-12'><span className="badge bg-green">{getName(record.order_stage)}</span></div>)
//     } else if (record.order_stage === 'cancelled') {
//         word = (<div className='col-md-12'><span className="badge bg-red">{getName(record.order_stage)}</span></div>)
//
//     }
//     return (<div className="row">{word}</div>)
// }

let WarrantyStatus = function (props) {
    let
        record = props.record || props.form_values || {},
        word = null,
        validity = add_years(new Date(record.order_date), 1)
    if (!record.order_date){
        return null
    }
    if (new Date() <= validity) {
        word = (<div className='col-md-12'><span className="badge bg-green">Valid</span></div>)
    } else {
        word = (<div className='col-md-12'><span className="badge bg-red">Expired</span></div>)
    }
    return (<div className="row">{word}</div>)
}

const OSMMap_modal = function (props) {
    let ThisComponent = connect(mapStateToProps, mapDispatchToProps)(class extends OSMMap {
    })
    return <ThisComponent {...props} style={{height: '350px'}} zoom={13}/>
}

// let hide = (field, equal_list, lines) => ({
//     type: 'custom', component: function (props) {
//         let {form_values} = props
//         return this.populateForm({
//             lines: [
//                 // (equal_list.includes(form_values[field])) ? lines : [{}]
//                 lines
//             ]
//         }).populated_form
//     }
// })
let
    // delivery_date = {name: 'Delivery Date', id: 'delivery_date', type: 'date', span: 3, required: true, defaultValue: current_date()},
    // delivery_time = {name: 'Delivery Time', id: 'delivery_time', type: 'time', showInTable: false, span: 3},
    // transport_paid = {name: 'Delivery Charges', id: 'transport_paid', type: 'currency', defaultValue: 0},
    transport_charged = {name: 'Transport Charged', id: 'transport_charged', type: 'currency', defaultValue: 0, showInTable: true},
    amount_sent = {name: 'Amount Paid', id: 'amount_sent', type: 'currency', defaultValue: 0, required: true},
    // turnover = {name: 'Actual Turnover', id: 'turnover', type: 'currency', disabled: true},
    // balance = {name: 'Balance', id: 'balance', type: 'currency', showInModal: true, disabled: true, showInTable: false},
    mark_up = {name: 'Markup', id: 'mark_up', type: 'currency', showInModal: true, disabled: true, showInTable: true},
    investor = {name: 'Investor', id: 'investor_id', type: 'multiselect', source: 'investors.name', showInModal: false, disabled: true, showInTable: true, show_pk_in_list: false},
    dispatched_from = {name: 'Dispatched From', id: 'store_id', type: 'select', source: 'stores.description', span: 1, required: true, showInTable: true}

export default {
    type: 'table',
    name: 'orders',
    tab_name: 'Orders',
    table: 'orders',
    title: ['name', 'phone_no', 'location'],
    lines: [
        [
            {name: 'Customer Name', id: 'name', type: 'text'},
            {name: 'Car Reg.', id: 'plate_no', type: 'text'},
            {name: 'Make', id: 'make', type: 'select', source: 'vehicle_make.make'},
            {name: 'Model', id: 'model', type: 'select', source: 'vehicle_model.model', filterable: true, filter_reference: 'make'},
            // ], [
            //     {name: 'Latitude', id: 'lat', type: 'text', showInTable: false, defaultValue: -1.2853696},
            //     {name: 'Longitude', id: 'lon', type: 'text', showInTable: false, defaultValue: 36.8215137},
        ], [
            {name: 'Phone No', id: 'phone_no', type: 'text', required: true},
            {name: 'Fuel Type', id: 'fuel_type', type: 'select', options: [{value: 'petrol', name: 'Petrol'}, {value: 'diesel', name: 'Diesel'}], showInTable: false},
            {name: 'Location', id: 'location', type: 'text', showInTable: false},
            // {name: 'Stage', id: 'order_stage', type: 'select', options, showInTable: false, defaultValue: 'fulfilled'},
            {name: 'Order Date', id: 'order_date', type: 'date', defaultValue: current_date(), required: true},
            {name: 'Warranty', id: 'status', type: 'custom', component: WarrantyStatus, showInTable: true, showInModal: false},
            {name: 'Order Details', id: 'details', type: 'textarea', disabled: true, showInModal: false, attributes: {style: {textAlign: 'right'}}},
            // {name: 'Order Time', id: 'order_time', type: 'time', showInTable: false},
            // {name: 'Delivery Type', id: 'delivery_type', type: 'text', showInModal: false},
            // {name: 'Delivery Type', id: 'delivery_type', type: 'select', options: [{value: 'Pickup'}, {value: 'Drop Off'}], creatable: true, showInTable: false},
            // ], [
            // hide('order_stage', ['fulfilled'], [delivery_date]),
            // hide('order_stage', ['fulfilled'], [delivery_time]),
            // {name: 'Delivered', id: 'delivered', ...foreign_keys.yes_no_boolean, showInTable: false},
        ], [
            {
                type: 'custom', span:3, component: function (props) {
                    let {form_state} = props
                    if (form_state === 'insert') {
                        return this.populateForm({
                            lines: [
                                [],[
                                    {name: 'Item Type', id: 'item_id', type: 'select', source: 'items.description', showInTable: true, col:3, show_pk_in_list: false},
                                    {name: 'Buying Price', id: 'items.buying_price', type: 'currency', reference: 'item_id', disabled: false, col: 3},
                                    {name: 'Selling Price', id: 'items.selling_price', type: 'currency', reference: 'item_id', disabled: false, required: true, col: 3},
                                    {name: 'Quantity', id: 'quantity', type: 'number', defaultValue: 1, col: 1},
                                    // {name: 'Discount', id: 'discount', type: 'currency', col: 2, defaultValue: 0},
                                    {name: 'Investor', id: 'investor_id', type: 'select', source: 'investors.name', col: 2, show_pk_in_list: false, required: true},
                                ]
                            ]
                        }).populated_form
                    } else {
                        return null
                    }
                }
            }
        ], [
            {...order_items, below: true}
            // {name: 'Approx. Delivery Cost', id: 'delivery_cost', type: 'currency', showInTable: false},
            // {...transport_charged},
            // {...amount_sent, showInModal: false},
            // {...transport_paid, showInModal: false},
            // {...turnover, showInModal: false},
            // {...dispatched_from, showInModal: false},
        ], [
            // {...transport_charged, showInTable: false, name: transport_charged.name + ' to Customer', col: 3},
            {...transport_charged},
            {name: 'Total', id: 'total_cost', type: 'currency', disabled: true, showInModal: false},
            {...amount_sent, name: amount_sent.name + ' by Customer', showInTable: false},
            // {...amount_sent, showInModal: false},
            // turnover,
            // balance,
            mark_up,
            dispatched_from,
            investor
        ], [
            {}
        ], [
            {name: 'Notes', id: 'notes', type: 'textarea'},
            {
                type: 'custom', component: function (props) {
                    let {form_values: {map_location}} = props
                    return this.populateForm({
                        lines: [
                            [{name: 'Map Location', id: 'map_location', ...foreign_keys.yes_no_boolean, span: 3}],
                            map_location ? [{name: 'Latitude', id: 'lat', type: 'text', showInTable: false, defaultValue: -1.2853696, span: 3},] : [],
                            map_location ? [{name: 'Longitude', id: 'lon', type: 'text', showInTable: false, defaultValue: 36.8215137, span: 3}] : [],
                        ]
                    }).populated_form
                }, col: 8
            }, {
                type: 'custom', component: function (props) {
                    let {form_values: {map_location}} = props
                    return this.populateForm({
                        lines: [
                            [
                                map_location ? {type: 'custom', component: OSMMap_modal, col: 12} : {}
                            ]
                        ]
                    }).populated_form
                }, col: 12
            }
        ]
    ],
    totals: [
        {
            'details': function (objects) {
                let ret = objects.map(record => (record['count'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                return `${ret} items`
            },
            'transport_charged': function (objects) {
                let ret = objects.map(record => (record['transport_charged'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                return ret
            },
            'transport_paid': function (objects) {
                let paid = objects.map(record => (record['transport_paid'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                // let charged = objects.map(record => (record['transport_charged'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                return paid
            },
            'amount_sent': function (objects) {
                let ret = objects.map(record => (record['amount_sent'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                return ret
            },
            'total_cost': function (objects) {
                let ret = objects.map(record => (record['total_cost'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                return ret
            },
            'turnover': function (objects) {
                let ret = objects.map(record => (record['turnover'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                return ret
            },
            'balance': function (objects) {
                let ret = objects.map(record => (record['balance'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                return ret
            },
            'mark_up': function (objects) {
                let ret = objects.map(record => (record['mark_up'] || 0)).reduce((partial_sum, a) => partial_sum + a, 0)
                return ret
            },
        },
    ],
    processor: function (state, props, ...others) {
        let new_state = {}
        if (state && state.map_location) {
            if (!state.lat) {
                new_state.lat = -1.2853696
            }
            if (!state.lon) {
                new_state.lon = 36.8215137
            }
            return new_state
        }
    }
}